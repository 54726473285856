<template>
  <div class="contents expanded pt-0">
    <div class="container-fluid">
      <div class="col-2"></div>
      <div class="row">
        <div class="col-12">
          <p class="row p-3 d-flex justify-content-end" v-html="icon.close"></p>
        </div>
      </div>
      <!-- -------------------- card head--------------- -->
      <!-- {{ TimelineDetail[0] }} -->
      <div v-if="TimelineDetail.length > 0" class="row cardleft">
        <div class="col-12 card datacard bgcard">
          <div class="row">
            <div class="col-8">
              <div class="m-0 p-3 font-Roboto">
                <p class="mainfont">{{ TimelineDetail[0].power }} kWp</p>
                <p class="title m-0 p-0">
                  เริ่ม {{ TimelineDetail[0].start_date }}
                </p>
                <p class="subtitle m-0 p-0">
                  {{ TimelineDetail[0].customer_name }}
                </p>
              </div>
            </div>
            <!-- -------------------- -->
            <div class="col m-0 p-0">
              <div class="m-0 p-3 font-Roboto text-right">
                <p class="fontmonth m-0 p-0">เดือน</p>
                <p class="fontmonthdata m-0 p-0">
                  {{ TimelineDetail[0].last_month }}
                </p>
              </div>
            </div>
          </div>
          <!-- ------------------------- -->
          <div class="row">
            <div class="col-6">
              <div class="m-0 p-3">
                <img src="/images/logoae.png" class="logoae" alt="" />
                <p class="aefont mt-2">
                  AESOLAR ความเป็นเยอรมัน Tier 1 NFC ที่เป็นหนึ่งเดียวในโลก
                  รวมถึงรับประกัน 30 ปี
                </p>
                <p>
                  <span class="fontjanon">จำนวน</span>&nbsp;&nbsp;&nbsp;<span
                    class="fontpan"
                    >{{
                      TimelineDetail[0].solar_count == null
                        ? "_"
                        : TimelineDetail[0].solar_count
                    }}
                    แผ่น</span
                  >
                </p>
              </div>
            </div>
            <!-- -------------------- -->
            <div class="col-6 m-0 p-0">
              <div class="m-0 p-3 text-right">
                <img src="/images/logohuawei.jpg" class="logohuawei" alt="" />
                <p class="huaweifont mt-4">
                  อินเวอร์เตอรที่เป็นที่นิยมมาก ในประเทศไทยทั้งเรื่องความทนทาน
                  ความปลอดภัยรวมถึงรับประกัน 30 ปี
                </p>
                <p>
                  <span class="fontjanon">จำนวน</span>&nbsp;&nbsp;&nbsp;<span
                    class="fontpan"
                    >{{
                      TimelineDetail[0].inverter_count == null
                        ? "_"
                        : TimelineDetail[0].inverter_count
                    }}
                    เครื่อง</span
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- --------------------- -->
        </div>
      </div>
      <!-- -------- -->
      <!-- <div class="row cardleft">
        <div class="mycol-12 card datacard bgcard">
          <div class="row">
            <div class="col-8">
              <div class="m-0 p-3 font-Roboto">
                <p class="mainfont">5 kWp</p>
                <p class="title m-0 p-0">เริ่ม 30-8-2564</p>
                <p class="subtitle m-0 p-0">คุณพงศกร สำลี</p>
              </div>
            </div>
            
            <div class="col m-0 p-0">
              <div class="m-0 p-3 font-Roboto text-right">
                <p class="fontmonth m-0 p-0">เดือน</p>
                <p class="fontmonthdata m-0 p-0">10</p>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6 dis">
              <div class="m-0 p-3">
                <img src="/images/logoae.png" class="logoae" alt="" />
                <p class="aefont mt-2">
                  AESOLAR ความเป็นเยอรมัน Tier 1 NFC ที่เป็นหนึ่งเดียวในโลก
                  รวมถึงรับประกัน 30 ปี
                </p>
                <p>
                  <span class="fontjanon">จำนวน</span>&nbsp;&nbsp;&nbsp;<span
                    class="fontpan"
                    >11 แผ่น</span
                  >
                </p>
              </div>
            </div>
            
            <div class="col-6 dis m-0 p-0">
              <div class="m-0 p-3 text-right">
                <img src="/images/logohuawei.jpg" class="logohuawei" alt="" />
                <p class="huaweifont mt-4">
                  อินเวอร์เตอรที่เป็นที่นิยมมาก ในประเทศไทยทั้งเรื่องความทนทาน
                  ความปลอดภัยรวมถึงรับประกัน 30 ปี
                </p>
                <p>
                  <span class="fontjanon">จำนวน</span>&nbsp;&nbsp;&nbsp;<span
                    class="fontpan"
                    >1 เครื่อง</span
                  >
                </p>
              </div>
            </div>
          </div>
           
        </div>
      </div> -->
      <!-- --------------------end card head--------------- -->
      <!-- -------------------- card body--------------- -->
      <div class="row mt-5 p-1 font-Roboto">
        <div class="col-12 card">
          <div class="mx-0 my-5 p-0">
            <div class="row" v-for="(e, i) in datadoc" :key="i">
              <div class="col-4">
                <p class="fontdate">{{ e.data[0]["predicted_at"] }}</p>
              </div>
              <!-- ----------------------- -->
              <div
                class="col position-relative"
                :class="i !== Number(datadoc.length) - 1 ? 'boderbody' : ''"
              >
                <!-- {{ i }}:{{ Number(datadoc.length) - 1 }} -->
                <p class="fonttitle" v-if="e.name !== ''">{{ e.name }}</p>
                <p v-for="(e1, i1) in e.data" :key="i1">
                  <span class="fontdate">{{ e1.text }}</span
                  >&nbsp;&nbsp;
                  <span v-if="e1.status !== 'wait'" v-html="icondone"></span>
                </p>

                <div
                  :class="
                    e.data[0]['group_status'] !== 'wait' ? 'pointdone' : 'point'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- --------------------end card body--------------- -->
    </div>
    <!-- <div class="card m-0 p-0 h-100"></div> -->
  </div>
</template>

<script>
import ScrollMagic from "scrollmagic";
export default {
  name: "QrUser",
  props: ["id"],

  components: {},
  data() {
    return {
      icondone: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#20c997" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>`,
      datadoc: [
        // {
        //   date: "25/8/2564",
        //   title: "Transfer Approved",
        //   detail: ["เซ็นสัญญา"],
        // },
        // {
        //   date: "11/8/2564",
        //   title: "Collect Documents",
        //   detail: ["เก็บเงิน30%"],
        // },
        // {
        //   date: "17/9/2564",
        //   title: "Send Documents",
        //   detail: [
        //     "วันที่ยื่นเอกสารหน่อยงานรัฐ",
        //     "วันที่ได้รับเอกสารใบอนุญาต-ขึ้นงานได้เลย",
        //   ],
        // },
        // {
        //   date: "5/10/2564",
        //   title: "Purchase Requisition",
        //   detail: ["ส่งมอบ"],
        // },
        // {
        //   date: "",
        //   title: "Collect Documents",
        //   detail: ["เรียกเก็บ70%"],
        // },
      ],
      icon: {
        close: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.707 9.70703L13.414 12L15.707 14.293C16.098 14.684 16.098 15.316 15.707 15.707C15.512 15.902 15.256 16 15 16C14.744 16 14.488 15.902 14.293 15.707L12 13.4139L9.70697 15.707C9.51197 15.902 9.256 16 9 16C8.744 16 8.48803 15.902 8.29303 15.707C7.90203 15.316 7.90203 14.684 8.29303 14.293L10.586 12L8.29303 9.70703C7.90203 9.31603 7.90203 8.68397 8.29303 8.29297C8.68403 7.90197 9.31597 7.90197 9.70697 8.29297L12 10.5861L14.293 8.29297C14.684 7.90197 15.316 7.90197 15.707 8.29297C16.098 8.68397 16.098 9.31603 15.707 9.70703ZM22 9V15C22 18.859 18.86 22 15 22H9C5.14 22 2 18.859 2 15V9C2 5.141 5.14 2 9 2H15C18.86 2 22 5.141 22 9ZM20 9C20 6.243 17.757 4 15 4H9C6.243 4 4 6.243 4 9V15C4 17.757 6.243 20 9 20H15C17.757 20 20 17.757 20 15V9Z" fill="#41416E"/>
      </svg>
      `,
      },
      reloadmodal: "",
      ofs: 0,
      lmt: 4,
      page: 0,
      data: [],
      TimelineDetail: [],
    };
  },
  mounted() {
    // this.scroll();
    // this.scroll1();
    this.getdata();
    this.GetStageTimelineDetailByJobId();
  },

  methods: {
    async GetStageTimelineDetailByJobId() {
      try {
        let data = { jobStageNoUuid: this.id };

        let getAPI = await this.$API.call_API(
          "put",
          "workflow/qruser",
          data
        );
        // console.log(getAPI.data.data);
        this.TimelineDetail = getAPI.data.data;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async getdata() {
      try {
        let data = { jobId: this.id };

        let getAPI = await this.$API.call_API(
          "post",
          "workflow/qruser",
          data
        );

        let getdata = getAPI.data.data;

        let groupdata = [];

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            groupdata.push({ name: i, data: [] });
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            if (getarray == -1) {
              groupdata.push({ name: getdata[i].group_stage_name, data: [] });
            }
          }
        }

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            let getarray = groupdata.findIndex((e) => e.name === i);

            groupdata[getarray].name = "";
            let datalength = groupdata[getarray].data.length;
            groupdata[getarray].data.push(getdata[i]);
            groupdata[getarray].data[datalength].jsnid =
              getdata[i].job_stage_node_id;
            //console.log(groupdata[getarray].data[datalength]);
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            //console.log(getarray);
            let datalength = groupdata[getarray].data.length;
            groupdata[getarray].data.push(getdata[i]);
            // console.log(getdata[i].job_stage_node_id);
            groupdata[getarray].data[datalength].jsnid =
              getdata[i].job_stage_node_id;
            //console.log(groupdata[getarray].data[datalength]);
          }
        }
        // console.log(groupdata);
        this.datadoc = groupdata;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    scroll: function () {
      var controller = new ScrollMagic.Controller();
      var scene = new ScrollMagic.Scene({
        triggerElement: ".mycol-12",
        triggerHook: 0,
        // duration: "20%",
      })
        // .setClassToggle(".cardleft", "show")
        .setClassToggle(".mycol-12", "mycol-10")
        .addTo(controller);
    },
    scroll1: function () {
      var controller = new ScrollMagic.Controller();

      var scene = new ScrollMagic.Scene({
        triggerElement: ".dis",
        triggerHook: 0,
        // duration: "20%",
      })
        // .setClassToggle(".cardleft", "show")
        .setClassToggle(".dis", "d-none")
        .addTo(controller);
    },
    async GetJobByUser() {
      try {
        //this.data=[]
        let data = { mode: 1, ofs: this.ofs * this.lmt, lmt: this.lmt };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetJobByUser",
          data
        );
        // console.log(getAPI.data.data);
        this.data = getAPI.data.data;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    callmodal() {
      this.reloadmodal = Date.now();
    },
    goto() {
      this.$router.push({ name: "WorkFlowPage" });
    },
  },
};
</script>

<style scoped>
.mycol-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.mycol-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91%;
  flex: 0 0 91%;
  max-width: 91%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.mycol-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.mycol-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
}
.mycol-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.mycol-10 {
  -webkit-box-flex: 0;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  width: 100%;
  padding-right: 15px;
}
.mycol-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.mycol-61 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.point {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 0px;
  left: -4px;
  border-radius: 50px;
  background: #000000;
}
.pointdone {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 0px;
  left: -4px;
  border-radius: 50px;
  background: #20c997;
}
.boderbody {
  border-left: 1px solid #000000;
}
.fonttitle {
  color: #20c997;
  font-weight: 400;
  font-size: 0.875em;
}
.fontdate {
  color: #000000;
  font-weight: 400;
  font-size: 0.61em;
}
.fontjanon {
  color: #000000;
  font-weight: 500;
  font-size: 0.68em;
}
.fontpan {
  color: #000000;
  font-weight: 700;
  font-size: 0.68em;
}
.logoae {
  width: 25vw;
}
.aefont {
  color: #000000;
  font-weight: 400;
  font-size: 0.68em;
}
.huaweifont {
  color: #000000;
  font-weight: 400;
  font-size: 0.56em;
}
.logohuawei {
  width: 20vw;
}
.fontmonth {
  color: #000000;
  font-weight: 400;
  font-size: 1.125em;
}
.fontmonthdata {
  color: #000000;
  font-weight: 400;
  font-size: 3em;
}
.mainfont {
  color: #000000;
  font-weight: 400;
  font-size: 2.25em;
}
.title {
  color: #000000;
  font-weight: 400;
  font-size: 0.875em;
}
.subtitle {
  color: #000000;
  font-weight: 400;
  font-size: 0.687em;
}
.bgcard {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}

.contents.expanded div div.cardleft {
  margin-left: -70px;
  /* position: fixed; */
  /* z-index: 1000; */
}

.datacard {
  padding-left: 54px;
}
.bgcard {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
@media (max-width: 1150px) {
  .contents.expanded {
    padding-left: 54px;
  }
}
</style>